.menu {
    margin: 0;
    padding: 30px;
    text-align: center;
    background-color: #fff;
    box-shadow: #c5c7d5 2px 1px 10px;
}

.menulink {
    background-color: #fff;
    text-decoration: none;
    color: #3A3B41;
    font-size: large;
    font-weight: bold;
    padding: 10px;
    margin: 30px;
}
.menulink :hover{
   cursor: pointer;
}