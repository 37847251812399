.app {
    margin: 10px 30px;
    padding: 10px;
}

.nameTitle {
    text-align: center;
    margin: 20px 0 40px;
    font-size: 30pt;
    font-weight: bold;
    background-color: rgb(188, 190, 193);
}

img {
    width: 100%;
}

.table,
.tabledata {
    margin: auto;
    border: 1px solid rgb(105, 104, 104);
    border-collapse: collapse;
    text-align: center;
    padding: 8px;
}

.backBtndiv {
    text-align: center;
    margin: 80px;

}

.backBtn {
    border: 2px solid #e0e8ea;
    border-radius: 50px;
    background-color: #cae8f2;
    padding: 15px 30px 15px;
    text-align: center;
    font-weight: bold;
    font-size: 14pt;
    box-shadow: #c5c7d5 1px 3px 5px;
}

.backBtntext {
    text-decoration: none;
}
.backBtntext :hover{
    cursor: pointer;
 }